import React, { PureComponent } from 'react';
import dayjs from 'dayjs';
import { Button, DatePicker, Form } from 'antd';
import { Dialog, ViewTable } from '@/components';
import saleDetailMobx from '../saleDetailMobx';
import { observer } from 'mobx-react';
import Layout from '@/layout/Layout';
import WebApi from '@/web/api';

const { RangePicker } = DatePicker;

interface Props {
  onOk(data: string[]): void
}

@observer
export default class PrintModal extends PureComponent<Props> {

  private defaultTime = [dayjs().subtract(1, 'd').startOf('d'), dayjs()];

  private table: ViewTable | null = null;

  private reqData: {
    customerId: string
    dateRange: [string, string],
  } = {
    customerId: '',
    dateRange: [this.defaultTime[0].format('YYYY-MM-DD HH:mm:ss'), this.defaultTime[1].format('YYYY-MM-DD HH:mm:ss')],
  }

  state: {
    visible: boolean
    selectedRowKeys: string[]
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      selectedRowKeys: [],
    };
  }

  render() {
    const { onOk } = this.props;
    const { visible, selectedRowKeys } = this.state;
    return (
      <Dialog
        title="打印订单"
        visible={visible}
        destroyOnClose
        block
        footer={[
          <Button key="back" onClick={() => this.setState({visible: false})}>
            取消
          </Button>,
          <Button key="only" type="primary" onClick={() => {
            onOk([saleDetailMobx.id]);
          }}>
            仅打印本单
          </Button>,
          <Button key="every" type="primary" onClick={() => {
            onOk([...selectedRowKeys, saleDetailMobx.id]);
          }}
            disabled={selectedRowKeys.filter(item => item !== saleDetailMobx.id).length === 0}>
            合并打印
          </Button>,
        ]}
      >
        <Layout.FlexCol>
          <Form layout="inline">
          <Form.Item label="订单号" style={{marginRight: 50}}>{saleDetailMobx.id}</Form.Item>
            <Form.Item label="客户" style={{marginRight: 50}}>{saleDetailMobx.customerName}</Form.Item>
            <Form.Item label="下单时间（30日内）">
              <RangePicker
                style={{width: 240}}
                ranges={{
                  '今日': [dayjs().startOf('d') as any, dayjs() as any],
                  '2日内': [dayjs().subtract(1, 'day').startOf('d') as any, dayjs() as any],
                  '3日内': [dayjs().subtract(2, 'day').startOf('d') as any, dayjs() as any],
                  '4日内': [dayjs().subtract(3, 'day').startOf('d') as any, dayjs() as any],
                  '5日内': [dayjs().subtract(4, 'day').startOf('d') as any, dayjs() as any],
                  '6日内': [dayjs().subtract(5, 'day').startOf('d') as any, dayjs() as any],
                  '本周': [dayjs().startOf('w') as any, dayjs().endOf('w') as any]
                }}
                disabledDate={(current) => {
                  return (current && current > dayjs().endOf('day')) || (current && current < dayjs().subtract(1, 'month'));
                }}
                format="MM月DD日"
                defaultValue={this.defaultTime as any}
                onChange={this.onPickerChange}
              />
            </Form.Item>
          </Form>
          <div style={{marginTop: 24}}>
            <span>订单列表(已选 <span style={{color: '#3871e0'}}>
              {selectedRowKeys.length}</span>)
            </span>
          </div>
          <div style={{flex: 1, overflow: 'hidden'}}>
            <ViewTable
              ref={r => this.table = r}
              rowKey={(row: any) => row.saleId}
              rowSelection={{
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                  this.setState({
                    selectedRowKeys,
                  });
                },
              }}
              columns={columns as any[]}
              beforeRender={(dataSource) => {
                return dataSource
              }}
              fetch={(data) => {
                return WebApi.sale_order_paging({...data, ...this.reqData})
              }}
            />
          </div>
        </Layout.FlexCol>
      </Dialog>
    )
  }

  public open = () => {
    this.reqData.customerId = saleDetailMobx.customerId;
    this.setState({
      visible: true,
    });
  }

  // 时间
  private onPickerChange = (value) => {
    this.reqData.dateRange[0] = dayjs(value[0]).format('YYYY-MM-DD');
    this.reqData.dateRange[1] = dayjs(value[1]).format('YYYY-MM-DD');
    this.table?.reset(this.reqData);
  }
}

const columns = [
  {
    title: '订单号',
    dataIndex: 'saleId',
    width: 180,
    align: 'center'
  },
  {
    title: '订单状态',
    width: 100,
    align: 'center',
    dataIndex: 'orderStatusDesc'
  },
  {
    title: '打印状态',
    dataIndex: 'printStatusDesc',
    width: 100,
    align: 'center',
  },
  {
    title: '收款状态',
    dataIndex: 'receivedStatusDesc',
    width: 100,
    align: 'center'
  },
  {
    title: '下单时间',
    dataIndex: 'createdAt',
    titleAlign: 'left',
    width: 'auto',
  },
]