import React from "react";

export default (props: {
  isGift: 1 | 0;
  children?: React.ReactNode;
}) => {
  return <div style={{display: 'flex', alignItems: 'center'}}>
    {
      props.isGift === 1 &&
      <i className="iconfont icon-zeng" style={{color: '#ea3323', fontSize: 16, lineHeight: 1, marginRight: 2}}/>
    }
    <span style={{lineHeight: 1}}>{props.children}</span>
  </div>
}