import { observer } from "mobx-react";
import { Tag } from 'antd';
import React from "react";
import OrderStatusDesc from '../components/OrderStatusDesc';
import saleDetailMobx from '../saleDetailMobx';

export default observer(() => {
  return <div>
    <p>
      <OrderStatusDesc>{saleDetailMobx.orderStatusDesc}</OrderStatusDesc>
    </p>
    <p>
      <span style={{display: 'inline-block', width: 540}}>
        <strong style={{marginRight: 10}}>订货单号：</strong>
        {saleDetailMobx.id}
        <Tag color="blue" style={{marginLeft: 10}}>{saleDetailMobx.orderWayDesc}</Tag>
      </span>
      <strong style={{marginRight: 10}}>下单时间：</strong>{saleDetailMobx.createdAt}
    </p>
    <p>
      <span style={{display: 'inline-block', width: 552}}>
        <strong style={{marginRight: 10}}>客户名称：</strong>{saleDetailMobx.customerName}
      </span>
      <strong style={{marginRight: 10}}>制单人：</strong>{saleDetailMobx.makeOrderBy}
    </p>
    <p>
      <span style={{display: 'inline-block', width: 540}}>
        <strong style={{marginRight: 10}}>收货地址：</strong>
        {saleDetailMobx.consigneeName} {saleDetailMobx.consigneeMobile} {saleDetailMobx.consigneeAddress}
      </span>
      <strong style={{marginRight: 10}}>备注说明：</strong>
      {saleDetailMobx.remark}
    </p>
  </div>
})