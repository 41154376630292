import React from 'react';
import { message } from 'antd';
import dayjs from 'dayjs';
import { Dialog, ViewTable, Layout, Button } from '@/components';
import DeliveryForm from '../components/DeliveryForm';

import saleDetailMobx from '../saleDetailMobx';
import { SALE_STATUS } from '@/enum-data/enum';
import { observer } from 'mobx-react';
import { hideLoading, showLoading } from '@/components/message/message';
import OutStockDialog from './OutStockDialog';
import SaleInfo from '../sale_order_info/SaleInfo';
import GiftName from '../../components/GiftName';
import WebApi from '@/web/api';
import { LibAuthCode } from 'lib/Auth';

@observer
export default class SaleDetailOutStock extends React.PureComponent<PagePropsType> {
  private ouStockDialog = React.createRef<OutStockDialog>();

  state: {
    pageSize: number,
    visible: boolean,
    stockLoading: boolean,
    productStocks: any[],
    visible2: boolean,
  }
  constructor(props) {
    super(props);

    this.state = {
      pageSize: 100,
      visible: false,
      stockLoading: true,
      productStocks: [],
      visible2: false,
    };
  }

  clickOutStockButton = () => {
    this.ouStockDialog.current?.open();
  }

  render() {
    const { visible,visible2 } = this.state;

    return (
      <div>
        <SaleInfo/>

        <OutStockDialog ref={this.ouStockDialog}/>

        {/* 出库记录 */}
        <Layout.Title icon="icon-chuku" style={{marginTop: 30}}>出库记录</Layout.Title>
        <ViewTable
          pagination={false}
          columns={[
            // {
            //   title: '出库编号',
            //   dataIndex: 'saleOutStockId',
            //   align: 'center',
            //   width: 200
            // },
            {
              title: '商品',
              dataIndex: 'productName',
              width: 200,
              render: (value, row) => {
                return <GiftName isGift={row.isGift}>{value}</GiftName>
              }
            },
            {
              title: '规格',
              dataIndex: 'skuName',
              width: 240
            },
            {
              title: '进货价格',
              dataIndex: 'price',
              width: 150,
              align: 'right',
              render: value => value || '--',
            },
            {
              title: '供应商',
              dataIndex: 'supplierName',
              width: 200,
              render: value => value || '--',
            },
            {
              title: '仓库',
              dataIndex: 'repositoryName',
              width: 150
            },
            {
              title: '出库数量',
              dataIndex: 'count',
              align: 'right',
              width: 100
            },
            // {
            //   title: '出库时间',
            //   dataIndex: 'createdAt',
            //   align: 'center',
            //   width: 200
            // },
          ]}
          dataSource={saleDetailMobx.outStockList}
          rowKey={(row: any) => row.saleOutStockId}
        />

        {/* 发货记录 */}
        <Layout.Title icon="icon-daishenhe" style={{marginTop: 30}}>
          <span>发货记录</span>
          {
            saleDetailMobx.orderStatus === SALE_STATUS.DONE.value &&
            <Button.Edit authCode={LibAuthCode.saleOrder.update} onClick={() => this.setState({ visible2: true })} style={{paddingBottom: 2}}/>
          }
        </Layout.Title>
        <p>
          <span style={{display: 'inline-block', width: 400}}><strong style={{marginRight: 10}}>发货人: </strong>{saleDetailMobx.deliveryRecord?.sendBy}</span>
          <span><strong style={{marginRight: 10}}>发货日期: </strong>{saleDetailMobx.deliveryRecord?.deliveryDatetime}</span>
        </p>
        <p>
          <span style={{display: 'inline-block', width: 400}}><strong style={{marginRight: 10}}>物流公司: </strong>{saleDetailMobx.deliveryRecord?.deliveryCompany}</span>
          <span><strong style={{marginRight: 10}}>物流单号: </strong>{saleDetailMobx.deliveryRecord?.deliveryNumber}</span>
        </p>
        <p>
        <span style={{display: 'inline-block', width: 400}}><strong style={{marginRight: 10}}>发货备注: </strong>{saleDetailMobx.deliveryRecord?.deliveryRemark}</span>
        </p>
       
        <Dialog
          title="填写物流信息"
          visible={visible}
          full={false}
          onCancel={() => this.setState({ visible: false })}
          onOk={this.clickDelivery}
        >
          <DeliveryForm ref={r => this.deliverForm = r}/>
        </Dialog>

        <Dialog
          title="修改物流信息"
          visible={visible2}
          full={false}
          onCancel={() => this.setState({ visible2: false })}
          onOk={this.clickDeliveryUpdate}
        >
          <DeliveryForm ref={r => this.deliverForm2 = r} initialValues={{
            ...saleDetailMobx.deliveryRecord,
            deliveryDatetime: dayjs(saleDetailMobx.deliveryRecord.deliveryDatetime)
          }}/>
        </Dialog>
      </div>
    );
  }

  private deliverForm;
  private deliverForm2;

  // 点击发货
  public clickDeliveryButton = () => {
    this.setState({
      visible: true,
    });
  }

  // 发货
  private clickDelivery = async () => {
    try {
      const values = await this.deliverForm.validateFields();
      showLoading();
      WebApi.sale_order_deliver({
        saleOrderId: saleDetailMobx.id,
        datetime: dayjs(values.deliveryTime).format('YYYY-MM-DD HH:mm:ss'),
        company: values.deliveryCompany || '',
        number: values.deliveryNumber || '',
        remark: values.deliveryRemark || '',
      })
      .then(() => {
        message.success('保存成功');
        this.setState({
          visible: false,
        });
        saleDetailMobx.refreshSaleDetail();
      });
      hideLoading();
    } catch (e) {
      hideLoading();
      console.log(e);
    }
  };

  // 修改物流信息
  private clickDeliveryUpdate = async () => {
    try {
      const values = await this.deliverForm2.validateFields();
      showLoading();
      WebApi.sale_order_deliver_record_update({
        saleOrderId: saleDetailMobx.id,
        datetime: dayjs(values.deliveryDatetime).format('YYYY-MM-DD HH:mm:ss'),
        company: values.deliveryCompany || '',
        number: values.deliveryNumber || '',
        remark: values.deliveryRemark || '',
      })
      .then(() => {
        message.success('保存成功');
        saleDetailMobx.refreshSaleDetail();
        this.setState({
          visible2: false
        });
      })
      .finally(() => {
        hideLoading();
      });
    } catch (e) {
      console.log(e);
    }
  };
}
