import React from 'react';
import {BookOrderTable} from '@/components';

import saleDetailMobx from '../saleDetailMobx';
import { observer } from 'mobx-react';
import { Layout } from '@/components';
import OrderStatusDesc from '../components/OrderStatusDesc';

export default observer(() => {
  return (
    <div>
      <p>
        <OrderStatusDesc>{saleDetailMobx.receivedStatusDesc}</OrderStatusDesc>
      </p>
      <p>
        <strong>订货单号：</strong>{saleDetailMobx.id}
        <strong style={{marginLeft: 30}}>应收金额：</strong>{saleDetailMobx.payAmount}
      </p>

      <Layout.Title icon="icon-shoukuan" style={{marginTop: 30}}>收款记录</Layout.Title>
      <BookOrderTable
        dataSource={saleDetailMobx.paymentList}
        mode="received"
      />
    </div>
  );
})
