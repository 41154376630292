import React from 'react';
import { message } from 'antd';
import { Dialog, InputNumber, ViewTable } from '@/components';

import saleDetailMobx from '../saleDetailMobx';
import { observer } from 'mobx-react';
import GiftName from '../../components/GiftName';
import WebApi from '@/web/api';

@observer
export default  class OutStockDialog extends React.PureComponent {
  state: {
    stockLoading: boolean,
    outStocks: any[],
    saleDetailStocks: any[],
    outStockVisible: boolean,
  }
  constructor(props) {
    super(props);

    this.state = {
      stockLoading: true,
      outStocks: [],
      saleDetailStocks: [],
      outStockVisible: false,
    };
  }

  public open = () => {
    this.findSaleOutStocks();
    this.setState({
      outStockVisible: true,
    });
  }

  render() {
    const { outStocks, outStockVisible } = this.state;

    return <Dialog 
      title="填写出库信息"
      visible={outStockVisible}
      block
      onOk={this.clickOutStockOk}
      onCancel={() => this.setState({outStockVisible: false})}>
      <div>
        <div style={{color: 'orange', fontSize: 14, marginBottom: 16}}>
          *同一商品可选择一个或多个库存规格出库，不出库的库存规格不必填写出库数量
        </div>
        <ViewTable
          pagination={false}
          loading={this.state.stockLoading}
          dataSource={outStocks}
          rowKey={(row: any) => {
            return row.id;
          }}
          columns={[
            {
              title: '序号',
              width: 60,
              align: 'center',
              dataIndex: 'index',
              render: (value, row, index) => {
                const obj = {
                  children: Array.from(new Set(outStocks.slice(0, index + 1).map(s => s.skuId))).length,
                  props: {
                    rowSpan: 1,
                  },
                };
                if (index === 0 || (outStocks[index - 1] as any).id !== row.id) {
                  obj.props.rowSpan = row.stockLength;
                  return obj;
                }
                if ((outStocks[index - 1] as any).id === row.id) {
                  obj.props.rowSpan = 0;
                  return obj;
                }
                return obj;
              }
            },
            {
              title: '商品',
              width: 200,
              dataIndex: 'productName',
              render: (value, row, index) => {
                const obj = {
                  children: <GiftName isGift={row.isGift}>{value}</GiftName>,
                  props: {
                    rowSpan: 1,
                  },
                };
                if (index === 0 || (outStocks[index - 1] as any).id !== row.id) {
                  obj.props.rowSpan = row.stockLength;
                  return obj;
                }
                if ((outStocks[index - 1] as any).id === row.id) {
                  obj.props.rowSpan = 0;
                  return obj;
                }
                return obj;
              }
            },
            {
              title: '规格',
              dataIndex: 'skuName',
              width: 200,
              render: (value, row, index) => {
                const obj = {
                  children: value,
                  props: {
                    rowSpan: 1,
                  },
                };
                if (index === 0 || (outStocks[index - 1] as any).id !== row.id) {
                  obj.props.rowSpan = row.stockLength;
                  return obj;
                }
                if ((outStocks[index - 1] as any).id === row.id) {
                  obj.props.rowSpan = 0;
                  return obj;
                }
                return obj;
              }
            },
            {
              title: '待出库',
              width: 100,
              align: 'center',
              dataIndex: 'count',
              render: (value, row, index) => {
                const obj = {
                  children: value,
                  props: {
                    rowSpan: 1,
                  },
                };
                if (index === 0 || (outStocks[index - 1] as any).id !== row.id) {
                  obj.props.rowSpan = row.stockLength;
                  return obj;
                }
                if ((outStocks[index - 1] as any).id === row.id) {
                  obj.props.rowSpan = 0;
                  return obj;
                }
                return obj;
              }
            },
            // {
            //   title: '预出库',
            //   width: 100,
            //   align: 'center',
            //   render: (v, row, index) => {
            //     const totalOutCount = outStocks.filter(item => item.skuId === row.skuId).reduce((p, c) => {
            //       return Number(p) + Number(c.outCount)
            //     }, 0)
            //     const obj = {
            //       children: totalOutCount,
            //       props: {
            //         rowSpan: 1,
            //       },
            //     };
            //     if (index === 0 || (outStocks[index - 1] as any).productId !== row.productId) {
            //       obj.props.rowSpan = row.stockLength;
            //       return obj;
            //     }
            //     if ((outStocks[index - 1] as any).productId === row.productId) {
            //       obj.props.rowSpan = 0;
            //       return obj;
            //     }
            //     return obj;
            //   },
            // },
            {
              title: '库存',
              children: [
                {
                  title: '出库',
                  width: 100,
                  align: 'center',
                  render: (value, row: any, index) => {
                    if (!row.repositoryName) return {
                      children: '无库存信息',
                      props: {
                        colSpan: 0,
                      },
                    };
                    return <InputNumber.Count
                      min={0}
                      max={row.stockCount}
                      selected
                      value={row.outCount}
                      disabled={row.disabled || row.stockCount === 0}
                      style={{width: '100%'}}
                      placeholder={row.disabled ? '库存不足' : '出库数量'}
                      onChange={number => {
                        const next = [...outStocks];
                        next[index].outCount = number;
                        this.setState({
                          outStocks: next
                        });
                      }}
                    />
                  },
                },
                {
                  title: '库存',
                  width: 100,
                  align: 'center',
                  dataIndex: 'stockCount',
                  render: (v, row) => {
                    return {
                      children: v,
                      props: {
                        colSpan: row.repositoryName ? 1 : 0,
                      },
                    };
                  }
                },
                {
                  title: '仓库',
                  width: 150,
                  dataIndex: 'repositoryName',
                  render: (v, row) => {
                    return {
                      children: v,
                      props: {
                        colSpan: row.repositoryName ? 1 : 0,
                      },
                    };
                  }
                },
                {
                  title: '进价',
                  width: 100,
                  align: 'center',
                  dataIndex: 'price',
                  render: (v, row) => {
                    if (!row.repositoryName) return {
                      children: <div style={{textAlign: 'center'}}>无库存信息</div>,
                      props: {
                        colSpan: 5,
                      },
                    };
                    return v || 0;
                  }
                },
                {
                  title: '供应商',
                  width: 'auto',
                  dataIndex: 'supplierName',
                  render: (v, row) => {
                    return {
                      children: v || '--',
                      props: {
                        colSpan: row.repositoryName ? 1 : 0,
                      },
                    };
                  }
                },
              ]
            },
        ]}/>
      </div>
    </Dialog>
  }

  // 出库
  public findSaleOutStocks = async () => {
    // 查询库存信息
    WebApi.sale_order_out_stock_option_list({
      saleOrderId: saleDetailMobx.id,
    })
    .then((data: any) => {
      const outStocks: any[] = [];
      data.forEach(item => {
        const {items, ...reset} = item;
        if (Array.isArray(items) && items.length > 0) {
          item.items.forEach(stock => {
            outStocks.push({
              id: stock.skuId + stock.stockId + item.isGift,
              outCount: 0,
              stockLength: item.items.length,
              ...stock,
              ...reset,
            });
          })
        } else {
          outStocks.push({
            ...reset,
            id: item.skuId + item.stockId + item.isGift,
            stockLength: 1,
            repositoryName: null,
            stockCount: 0,
          });
        }
      })
      this.setState({
        outStocks,
        saleDetailStocks: data,
        stockLoading: false,
      });
    });
  }

  // 确认出库
  private clickOutStockOk = async () => {
    const { outStocks, saleDetailStocks } = this.state;

    const reqOutStocks: any[] = [];
    // 检查出库数量是否与订单一致
    for (const item of saleDetailStocks) {
      const itemStocks = outStocks.filter(o => o.saleDetailId === item.saleDetailId).filter(item => item.outCount > 0);
      if (itemStocks.length === 0) {
        message.error(`商品 “${item.productName}/${item.skuName}” 出库数量小于待出库数量`);
        return;
      }
      // 计算出库数量
      const totalOutCount = itemStocks.reduce((p, c) => {
        return Number(p) + Number(c.outCount)
      }, 0)
      if (totalOutCount > item.count) {
        message.error(`商品 “${item.productName}/${item.skuName}” 出库数量大于待出库数量`);
        return;
      }
      if (totalOutCount < item.count) {
        message.error(`商品 “${item.productName}/${item.skuName}” 出库数量小于待出库数量`);
        return;
      }
      reqOutStocks.push({
        saleDetailId: item.saleDetailId,
        items: itemStocks.map(os => {
          return {
            stockId: os.stockId,
            count: os.outCount
          };
        })
      });
    }

    WebApi.sale_order_out_stock({
      saleOrderId: saleDetailMobx.id,
      outStockList: reqOutStocks,
    })
    .then(() => {
      message.success('出库成功');
      this.setState({
        outStockVisible: false,
      });
      saleDetailMobx.refreshSaleDetail();
    })
  }
}
