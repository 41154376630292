import React from 'react';
import {ViewTable, Layout} from '@/components';
import saleDetailMobx from '../saleDetailMobx';
import { observer } from 'mobx-react';
import SaleInfo from '../sale_order_info/SaleInfo';
import GiftName from '../../components/GiftName';

@observer
export default class OrderSaleDetail extends React.PureComponent {
  render() {
    return <div>
        {/* 订单基本信息 */}
        <SaleInfo/>

        {/* 商品信息 */}
        <Layout.Title style={{marginTop: 30}}>订货商品</Layout.Title>
        <ViewTable
          flex1
          pagination={false}
          columns={columns as any[]}
          dataSource={saleDetailMobx.detailList}
          rowKey={(row: any) => row.id}
          footer={() => {
            return <div style={{textAlign: 'right'}}>应付金额：<span style={{fontSize: 18, color: 'red'}}>{saleDetailMobx.payAmount}</span></div>
          }}/>
    </div>
  }
}

const columns = [
  {
    title: '主图',
    dataIndex: 'imageLogoUrl',
  },
  // {
  //   title: '商品编号',
  //   dataIndex: 'productId',
  //   width: 180,
  //   align: 'center',
  // },
  {
    title: '商品',
    dataIndex: 'productName',
    width: 200,
    render: (v, row) => {
      return <GiftName isGift={row.isGift}>{v}</GiftName>
    },
  },
  {
    title: '规格',
    dataIndex: 'skuName',
    width: 200,
  },
  {
    title: '原价',
    dataIndex: 'originalPrice',
    align: 'right',
    width: 100,
  },
  {
    title: '参与活动',
    dataIndex: 'activityName',
    width: 200,
    render: value => value || '无',
  },
  {
    title: '订货价',
    dataIndex: 'price',
    align: 'right',
    width: 100,
  },
  {
    title: '数量',
    width: 100,
    align:'right',
    dataIndex: 'orderCount',
  },
  {
    title: '计量单位',
    width: 140,
    align: 'center',
    dataIndex: 'orderUnitName',
  },
  {
    title: '金额小计',
    width: 100,
    align: 'right',
    dataIndex: 'payAmount',
  },
  {
    title: '备注',
    width: 200,
    dataIndex: 'remark',
  }
];