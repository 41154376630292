import React from 'react';
import { Form, Input, DatePicker } from 'antd';
import dayjs from 'dayjs';

export default React.forwardRef((props: {
  initialValues?: any
}, ref: any) => {
  const { initialValues } = props;
  const values = { ...initialValues };
  if (typeof values.deliveryTime === 'string') {
    values.deliveryTime = dayjs(values.deliveryTime);
  }
  return <div>
    <Form ref={ref} initialValues={values} labelCol={{span: 4}}>
      <div>
        <Form.Item label="发货日期" name="deliveryDatetime" rules={[
          {
            required: true,
            message: '请选择发货日期',
          },
        ]}>
          <DatePicker showTime placeholder="选择" style={{width: '100%'}}/>
        </Form.Item>
      </div>
      <div style={{ marginTop: 15 }}>
        <Form.Item label="物流公司" name="deliveryCompany">
          <Input placeholder="填写" />
        </Form.Item>
      </div>
      <div style={{ marginTop: 15 }}>
        <Form.Item label="物流单号" name="deliveryNumber">
          <Input placeholder="填写" />
        </Form.Item>
      </div>
      <div style={{ marginTop: 15 }}>
        <Form.Item label="发货备注" name="deliveryRemark">
          <Input placeholder="填写" />
        </Form.Item>
      </div>
    </Form>
  </div>
});
