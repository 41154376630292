import React, { PureComponent } from 'react';
import { Tabs, message, Result, Input, Dropdown, Menu } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import CombinePrintDialog from './print_dialog/CombinePrintDialog';
import PrintDropdown, {print} from './components/print-dropdown/PrintDropdown';

import SaleProduct from './product/SaleProduct';
import OutStock from './out_stock/OutStockRecord';
import PaymentRecord from './payment_record/PaymentRecord';

import saleDetailMobx from './saleDetailMobx';
import { observer } from 'mobx-react';
import { routerMap } from '@/router/agent_router';
import { Dialog, AddressSelectModal, BookCreateDialog, Button, Layout } from '@/components';
import { hideLoading, showLoading } from '@/components/message/message';
import LibSaleOrderFunction from 'lib/function/SaleOrderFunction';
import ComEnum from 'lib/enum';
import WebApi from '@/web/api';
import WebAuth from '@/web/WebAuth';
import { LibAuthCode } from 'lib/Auth';

const { TabPane } = Tabs;

@observer
export default class OrderDetail extends PureComponent<RouterPropsType> {
  private printModal: CombinePrintDialog | null = null;
  private outStock: OutStock | null = null;
  private addressModal: AddressSelectModal | null = null;
  private bookCreateDialog = React.createRef<BookCreateDialog>();
  private remark;
  state: {
    tabKey: string;
    tip: string;
    visible: boolean;
    receivedVisible: boolean;
    mountPrintModal: boolean;
    remarkVisible: boolean;
  }

  private saleId: string = this.props.match?.params.id;

  constructor(props) {
    super(props);
    const getTabKey = (key) => {
      switch (key) {
        case '11': return 'detail';
        case '12': return 'out';
        case '13': return 'out';
        case '14': return 'receipt';
        default: return 'detail';
      }
    };
    this.state = {
      tabKey: getTabKey(props.match.params.key),
      tip: '',
      visible: false,
      receivedVisible: false,
      mountPrintModal: false,
      remarkVisible: false,
    };
  }

  componentDidMount() {
    this.findSaleDetail();
  }

  componentWillUnmount() {
    saleDetailMobx.exist = true;
  }

  findSaleDetail = () => {
    saleDetailMobx.findSaleDetail(this.saleId);
  }

  renderExtraButton = (): JSX.Element | null => {
    return (
      <Layout.TabBarExtra>
        {
          LibSaleOrderFunction.isWaitAudit(saleDetailMobx.orderStatus) && 
          <Button authCode={LibAuthCode.saleOrder.update} type="primary" onClick={this.clickAudit}>
            通过审核
          </Button>
        }
        {
          LibSaleOrderFunction.isWaitOutStock(saleDetailMobx.orderStatus) && 
          <Button authCode={LibAuthCode.saleOrder.update} type="primary" onClick={() => {
            this.outStock?.clickOutStockButton();
          }}>
            商品出库
          </Button>
        }
        {
          LibSaleOrderFunction.isWaitDeliver(saleDetailMobx.orderStatus) &&
          <Button authCode={LibAuthCode.saleOrder.update} type="primary" onClick={() => {
            this.outStock?.clickDeliveryButton();
          }}>
            发货
          </Button>
        }
        {
          !LibSaleOrderFunction.isPayed(saleDetailMobx.receivedStatus) &&
          <Button authCode={LibAuthCode.saleOrder.update} type="default" icon={<i className="iconfont icon-xinzeng"/>} onClick={() => {
            this.bookCreateDialog.current?.open();
          }}>添加付款记录</Button>
        }
        {
          LibSaleOrderFunction.isWaitAudit(saleDetailMobx.orderStatus) && WebAuth.hasAuth(LibAuthCode.saleOrder.update) && 
          <Dropdown
            overlayClassName="drop-button"
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={() => {
                  this.props.history.push(routerMap['sale.update'].getPath(saleDetailMobx.id));
                }}>
                  订货商品
                </Menu.Item>
                <Menu.Item onClick={() => {
                  this.addressModal?.open();
                }}>
                  收货地址
                </Menu.Item>
                <Menu.Item onClick={() => {
                  this.setState({
                    remarkVisible: true,
                  })
                }}>
                  订单备注
                </Menu.Item>
              </Menu>
            }
          >
            <Button.Edit onClick={() => {
              this.props.history.push(routerMap['sale.update'].getPath(this.saleId));
            }}>
              修改 <i style={{color: '#333', marginLeft: 3}} className="iconfont icon-arrow-down"></i>
            </Button.Edit>
          </Dropdown>
        }
        {
          LibSaleOrderFunction.isWaitOutStock(saleDetailMobx.orderStatus) && 
          <Button authCode={LibAuthCode.saleOrder.update} type="default" icon={<RollbackOutlined />} onClick={this.clickOrderBack}>
            退回订单审核
          </Button>
        }
        {
          WebAuth.hasAuth(LibAuthCode.saleOrder.print) &&
          <PrintDropdown status={saleDetailMobx.printStatus}
            clickPrintStatus={printStatus => this.clickUpdatePrintStatus(printStatus)}
            clickPrint={() => {
              this.printModal?.open()
            }}
          />
        }
        
        {
          LibSaleOrderFunction.isWaitAudit(saleDetailMobx.orderStatus) &&
          <Button authCode={LibAuthCode.saleOrder.update} type="default" icon={<i className="iconfont icon-butongyi"></i>} onClick={this.clickInvalid}>
            作废
          </Button>
        }
        {
          LibSaleOrderFunction.isInvalid(saleDetailMobx.orderStatus) && 
          <Button authCode={LibAuthCode.saleOrder.delete} type="primary" danger={true} onClick={this.clickDelete}>
            删除
          </Button>
        }
      </Layout.TabBarExtra>
    );
  }

  render() {
    const { tabKey, tip } = this.state;
    if (!saleDetailMobx.exist) {
      return <Result
        icon={<img src={require('../../../static/order-delete.png').default}/>}
        title="订货单已删除！"
        subTitle={`订货单号：${saleDetailMobx.id}`}
      />
    }
    return (
      <Layout height>
        <Tabs className="app-tabs" tabBarExtraContent={this.renderExtraButton()}
          onChange={key => {
            this.setState({ tabKey: key });
          }}
          activeKey={tabKey}
          animated={false}>
          <TabPane tab="订单信息" key="detail" forceRender>
            <SaleProduct/>
          </TabPane>
          <TabPane tab="出库/发货记录" key="out" forceRender>
            <OutStock ref={r => this.outStock = r}/>
          </TabPane>
          <TabPane tab="付款记录" key="receipt" forceRender>
            <PaymentRecord/>
          </TabPane>
        </Tabs>
      
        {/* 打印送货单 */}
        <CombinePrintDialog
          ref={r => this.printModal = r}
          onOk={this.clickPrintOk}
        />
        {/* 修改备注 */}
        <Dialog full={false} title="修改备注" visible={this.state.remarkVisible}
          onCancel={() => this.setState({remarkVisible: false})}
          onOk={this.clickUpdateRemarkOk}>
          <div>
            <Input.TextArea ref={r => this.remark = r} autoSize={{minRows: 4}} placeholder="填写备注"
              defaultValue={saleDetailMobx.remark}
            />
          </div>
        </Dialog>

        <AddressSelectModal
          ref={r => this.addressModal = r}
          customerId={saleDetailMobx.customerId}
          customerName={saleDetailMobx.customerName}
          onOk={this.clickUpdateAddress}
        />

        <BookCreateDialog
          ref={this.bookCreateDialog}
          shouldAmount={saleDetailMobx.payAmount}
          actualAmount={saleDetailMobx.payAmount}
          onOk={this.clickCreateReceivedOk}/>
      </Layout>
    )
  }

  // 修改备注
  clickUpdateRemarkOk = () => {
    WebApi.sale_order_remark_update({
      saleOrderId: saleDetailMobx.id,
      remark: this.remark.state.value
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        remarkVisible: false,
      });
      saleDetailMobx.refreshSaleDetail();
    })
  }

  // 收款
  clickCreateReceivedOk = async (values) => {
    showLoading(1);
    const { actualAmount, datetime, remark } = values;
    WebApi.sale_order_payment_create({
      saleOrderId: saleDetailMobx.id,
      shouldAmount: actualAmount,
      actualAmount,
      datetime,
      remark,
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        visible: false,
      });
      saleDetailMobx.refreshSaleDetail();
      this.bookCreateDialog.current?.close();
    })
    .finally(() => {
      hideLoading();
    })
  }

  // 选定地址
  clickUpdateAddress = (address: IConsigneeAddress) => {

    WebApi.sale_order_address_update({
      saleOrderId: saleDetailMobx.id,
      consigneeAddress: `${address.provinceName}${address.cityName}${address.areaName}${address.street}`,
      consigneeName: address.name,
      consigneeMobile: address.mobile,
    })
    .then(() => {
      message.success('保存成功');
      this.addressModal?.close();
      saleDetailMobx.refreshSaleDetail();
    })
    .catch(e => {
      message.error('修改收货地址出错:' + e.message);
    });
  }

  // 修改打印状态
  clickUpdatePrintStatus = (status) => {
    Dialog.confirm({
      title: '修改确认',
      content: `确定修改为${status === ComEnum.PRINT_STATUS.WAIT_PRINT.value ? '未' : '已'}打印？`,
      onOk: async () => {
        WebApi.sale_order_print_status_update({
          saleOrderIdList: [ this.saleId ],
          printStatus: status,
        })
        .then(() => {
          message.success('保存成功');
          this.findSaleDetail();
        })
        .catch(e => {
          message.error('修改打印状态出错:' + e.message);
        });
      },
    });
  }

  // 确认打印
  clickPrintOk = (data: string[]) => {
    WebApi.sale_order_print_data_list({
      saleOrderIdList: data
    })
    .then((data) => {
      print(data as any[]);
    })
  }

  private auditReason: string = '';

  // 退回
  clickOrderBack = () => {
    Dialog.info({
      title: '退回审核',
      content: <div>
        <p>确认将订货单 {this.saleId} 退回 ？ 如确定退回,请填写退回原因 :</p>
        <Input.TextArea onChange={e => {
          this.auditReason = e.target.value;
        }}/>
      </div>,
      okText: '确定',
      onOk: () => {
        if (!this.auditReason) {
          message.error('退回原因必须填写');
          return;
        }
        WebApi.sale_order_back_audit({
          saleOrderId: this.saleId,
          reason: this.auditReason,
        })
        .then(() => {
          message.success('订单已退回审核');
          saleDetailMobx.refreshSaleDetail();
        })
        .catch(e => {
          message.error('退回出错:' + e.message);
        });
      }
    });
  }

  // 作废
  clickInvalid = () => {
    Dialog.confirm({
      title: '作废确认',
      content: '确认作废吗？作废后不可恢复',
      onOk: async () => {
        WebApi.sale_order_invalid({saleOrderId: this.saleId})
        .then(() => {
          message.success('该订单已作废');
          saleDetailMobx.refreshSaleDetail();
        })
        .catch(e => {
          message.error('作废订单出错:' + e.message);
        })
      },
    });
  }

  // 通过
  clickAudit = async () => {
    const id = this.saleId;
    Dialog.confirm({
      title: '审核确认',
      content: `是否确定订货单 “${id}” 通过订单审核?`,
      onOk: async () => {
        WebApi.sale_order_audit({saleOrderId: this.saleId})
        .then(() => {
          message.success(`订货单 ${id} 已通过订单审核`);
          saleDetailMobx.refreshSaleDetail();
        })
        .catch(e => {
          message.error('审核订货单出错:' + e.message);
        })
      },
    });
  };

  // 删除
  clickDelete = () => {
    Dialog.confirm({
      title: '删除确认',
      content: `是否确定删除订单？删除后无法恢复`,
      onOk: async () => {
        WebApi.sale_order_delete({saleOrderId: this.saleId})
        .then(() => {
          message.success(`删除成功`);
          this.props.history.close();
        })
      },
    });
  }
}
