import { observable, action, runInAction, makeAutoObservable } from 'mobx';
import { message } from 'antd';
import WebApi from '@/web/api';

class SaleDetailMobx {
  id = '';
  saleId = '';
  totalCount = '';
  totalAmount = '';
  payAmount = '';
  favorableAmount = '';
  couponAmount = '';
  customerId = '';
  customerName = '';
  consigneeName = '';
  consigneeMobile = '';
  consigneeAddress = '';
  couponGetId = '';
  orderStatus = '';
  orderStatusDesc = '';
  receivedStatus = '';
  receivedStatusDesc = '';
  printStatus = '';
  printStatusDesc = '';
  orderWay = '';
  orderWayDesc = '';
  remark = '';
  makeOrderBy = '';
  returnFlag = '';
  auditReason = '';
  createdAt = '';
  updatedAt = '';

  @observable
  detailList = [];

  @observable
  outStockList = [];

  deliveryRecord: any = {};
  signInfo: any = {};
  receiptInfo: any = {}
  orderRemark;
  outStockInfo: any = {}

  @observable
  paymentList: any = []

  exist: boolean = true

  constructor() {
    makeAutoObservable(this);
  }

  @action
  public findSaleDetail(saleId) {
    WebApi.sale_order_detail(saleId)
    .then((data: any) => {
      runInAction(() => {
        this.id = saleId;
        for (let key in data) {
          this[key] = data[key]
        }
        this.exist = true;
      });
    })
    .catch(e => {
      if (e.message === 'Error: 订单不存在') {
        this.exist = false;
        return;
      }
      message.error(e.message);
    })
  }

  @action
  public refreshSaleDetail() {
    this.id && this.findSaleDetail(this.id);
  }
}

export default new SaleDetailMobx();